import { IS_APP } from "@/__main__/constants.mjs";
import { setVolatileKV } from "@/app/actions.mjs";

export function setup() {
  if (IS_APP) return;
  setVolatileKV("ads-skin", true);
}

export function teardown() {
  if (IS_APP) return;
  setVolatileKV("ads-skin", false);
}
